/* Add vertical gap between the text box and the "Confess" button */
.confess-button {
  margin-top: 20px; /* Adjust the value to control the gap */
}

.Post {
  text-align: center;
}

.Post-header {
  background-color: #282c34;
  min-height: calc(100vh - 56px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}